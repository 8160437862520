import React from "react";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";
import HomeButton from "../Components/HomeButton";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const TextMessageAuth = () => {
  const fontValue = useFont();
  const font = fontValue?.font;

  const docs = [
    {
      uri: "https://newbos-strapi-app.azurewebsites.net/uploads/ABC_Text_Messages_Authorization_220305f61e.docx",
      fileType: "docx",
    },
  ];

  const handleDownload = () => {
    const fileUrl =
      "https://newbos-strapi-app.azurewebsites.net/uploads/ABC_Text_Messages_Authorization_220305f61e.docx";
    const fileName = "ABC Text Messages Authorization.docx";

    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Page isLoading={fontValue?.loading}>
      <div className="bg-gray-300 ">
        <div className="lg:max-w-[1100px] m-auto ">
          <div className="flex justify-center pt-10">
            <p className={`${font?.SUHTX} text-black text-center`}>
              ABC Text Messages Authorization Form
            </p>
          </div>

          <div className="h-full pt-10">
            <div className="bg-white h-15 border-b-4 ">
              <button
                className={`${font?.DTX} bg-gradient-to-r from-[#FFCB00] to-[#F48B00] rounded-3xl pl-3 pr-3 m-3`}
                onClick={handleDownload}
              >
                Download
              </button>
            </div>
            {/* <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={docs}
              preload="true"
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false,
                },
                footer: { disableFooter: true },
              }}
              style={{ height: "100%", width: "100%", overflow: "hidden" }}
            /> */}
            <div>
              <img
                src="https://newbos-strapi-app.azurewebsites.net/uploads/ABC_Text_Messages_Authorization_jpg_02f4034aef.jpg"
                className="h-full"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <HomeButton />
    </Page>
  );
};

export default TextMessageAuth;
