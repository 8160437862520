import React, { createContext, useEffect, useState } from "react";
import { Route, Routes, Switch } from "react-router-dom";
import axios from "axios";
import TopBar from "./Components/TopBar";
import Home from "./Pages/Home";
import NewbosProduct from "./Pages/Product/NewbosProduct";
import MeetAndShare from "./Pages/MeetAndShare";
import NotFound from "./NotFound";
import Layout from "./Components/Layout";
import DataPrivacyPolicy from "./Pages/DataPrivacyPolicy";
import ContactUs from "./Pages/ContactUs";
import EULA from "./Pages/EULA";
import DigitalLibrary from "./Pages/DigitalLibrary";
import TextMessageAuth from "./Pages/TextMessageAuth";


function App() {
  return (
    <div className="App">
      <Layout>
        {/* This component handles the scroll */}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          {/* <Route
            path="/product/:productname"
            element={<NewbosProduct />}
          ></Route> */}
          <Route path="/meetandshare" element={<MeetAndShare />}></Route>
          <Route
            path="/dataprivacypolicy"
            element={<DataPrivacyPolicy />}
          ></Route>
          {/* <Route path="/contactus" element={<ContactUs />}></Route> */}
          <Route path="/eula" element={<EULA />}></Route>
          <Route path="/digitallibrary" element={<DigitalLibrary />}></Route>
          <Route path="/textmessageauth" element={<TextMessageAuth/>}></Route>
          <Route path="*" element={<Home />}></Route>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
