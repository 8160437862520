import React from "react";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
//import { FontContext } from "../App";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";
import { BlocksRenderer, BlocksContent } from "@strapi/blocks-react-renderer";
import { useNavigate, useParams } from "react-router-dom";

const ScheduleDemo = () => {
  const [error, setError] = useState(null);
  const [scheduleData, setscheduleData] = useState([]);
  const fontValue = useFont();
  const font = fontValue?.font;

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/shd?populate=*")
      .then(({ data }) => setscheduleData(data.data.attributes))
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <div id="ScheduleaDemo" key={scheduleData.SHD_HTX_01}>
        <div className="max-h-[400px] bg-gray-300 lg:pt-2">
          <div className="flex justify-center pt-4 pb-5 pl-2 pr-2">
            <p className={`${font?.SUHTX} text-black text-center`}>
              {scheduleData.SHD_HTX_01?.split(/\n/).map((line) => (
                <span key={`SUHTX_01schedulrDemo${line}`}>
                  {line} <br />
                </span>
              ))}
            </p>
          </div>
        </div>
        <div className="max-h-[400px] bg-[#ECEBEB] lg:pt-2">
          <div className="flex justify-center pt-6 pb-2 pl-2 pr-2">
            <p className={`${font?.SHD_SHDX_01} text-black text-center `}>
              {scheduleData.SHD_SHDX_01?.split(/\n/).map((line) => (
                <span key={`CATHTX_1schedulrDemo${line}`}>
                  {line} <br />
                </span>
              ))}
            </p>
          </div>
        </div>
        <div className=" w-full h-auto bg-[#ECEBEB]">
          <div className=" lg:max-w-[1100px] m-auto  lg:py-6 py-22 ">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3   gap-4 pt-4 pl-2 pr-2">
              {/* 1*/}
              <div className="border shadow-lg  hover:scale-105 duration-300 bg-[#FDFDFD] h-full">
                <img
                  src={
                    process.env.REACT_APP_STRAPI_API_URL +
                    scheduleData.SHD_IMG_01A?.data?.attributes?.url
                  }
                  alt=""
                  className="w-full h-[150px] object-cover "
                />
                <div className=" justify-between  bg-[#ED8B37] ">
                  <p
                    className={`${font?.SHDTX} text-white p-2`}
                    key={`BY1_DTX_01AschedulrDemo${scheduleData.SHD_HDTX_01}`}
                  >
                    {scheduleData.SHD_HDTX_01}
                  </p>
                </div>
                <div className="flex justify-between p-1">
                  <p className="p-1">
                    {scheduleData.SHD_DTX_01?.split(/\n/).map((line, i) => (
                      <span
                        key={`BY2_DTX_02AschedulrDemo${i}${line}`}
                        className={`${font?.SHDDTX} text-black`}
                      >
                        {line}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
              </div>
              {/* 2*/}
              <div className="border shadow-lg  hover:scale-105 duration-300 bg-[#FDFDFD] h-full">
                <img
                  src={
                    process.env.REACT_APP_STRAPI_API_URL +
                    scheduleData.SHD_IMG_02A?.data?.attributes?.url
                  }
                  alt=""
                  className="w-full h-[150px] object-cover "
                />
                <div className=" justify-between  bg-[#ED8B37] ">
                  <p
                    className={`${font?.SHDTX} text-white p-2`}
                    key={`BY1_DTX_01AschedulrDemo${scheduleData.SHD_HDTX_02}`}
                  >
                    {scheduleData.SHD_HDTX_02}
                  </p>
                </div>
                <div className="flex justify-between p-1">
                  <p className="p-1">
                    {scheduleData.SHD_DTX_02?.split(/\n/).map((line, i) => (
                      <span
                        key={`BY2_DTX_02AschedulrDemo${i}${line}`}
                        className={`${font?.SHDDTX} text-black`}
                      >
                        {line}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
              </div>
              {/* 3*/}
              <div className="border shadow-lg  hover:scale-105 duration-300 bg-[#FDFDFD] h-full">
                <img
                  src={
                    process.env.REACT_APP_STRAPI_API_URL +
                    scheduleData.SHD_IMG_03A?.data?.attributes?.url
                  }
                  alt=""
                  className="w-full h-[150px] object-cover "
                />
                <div className=" justify-between  bg-[#ED8B37] ">
                  <p
                    className={`${font?.SHDTX} text-white p-2`}
                    key={`BY1_DTX_01AschedulrDemo${scheduleData.SHD_HDTX_03}`}
                  >
                    {scheduleData.SHD_HDTX_03}
                  </p>
                </div>
                <div className="flex justify-between p-1">
                  <p className="p-1">
                    {scheduleData.SHD_DTX_03?.split(/\n/).map((line, i) => (
                      <span
                        key={`BY2_DTX_02AschedulrDemo${i}${line}`}
                        className={`${font?.SHDDTX} text-black `}
                      >
                        {line}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-[#ECEBEB] lg:pt-2">
          <div className="flex justify-center pt-4 pb-2 pl-2 pr-2">
            <p className={`${font?.SUHTX} text-black text-center`}>
              {scheduleData.SHD_HTX_02?.split(/\n/).map((line) => (
                <span
                  key={`SHD_HTX_02schedulrDemo${scheduleData.SHD_HTX_02}${line}`}
                >
                  {line} <br />
                </span>
              ))}
            </p>
          </div>
          <div className="lg:max-w-[1100px] m-auto  lg:py-6 py-2  lg:pt-2 ">
            <div className=" justify-start pt-2 pb-5">
              {scheduleData?.SHD_SHDX_02_BLG != undefined ||
              scheduleData?.SHD_SHDX_02_BLG != null ? (
                <div className="pt-2 pl-2 pr-2">
                  <BlocksRenderer
                    content={scheduleData?.SHD_SHDX_02_BLG}
                    blocks={{
                      paragraph: ({ children }) => (
                        <span className={`${font?.DTX} text-black `}>
                          {children}
                          <br />
                        </span>
                      ),
                      list: ({ children }) => (
                        <ul className="list-disc pl-6">
                          <span className={`${font?.DTX}`}>{children}</span>
                        </ul>
                      ),
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ScheduleDemo;
