const ewrite = (s) => {
  return btoa("Nb9" + s.split("").reverse().join(""));
};

const eread = (s) => {
  return atob(s).substring(3).split("").reverse().join("");
};

const handleFormChange = {
  a: eread("TmI5MU5pY2xORGEzUlRjb1YzTXZaR2F6UlNZc3BUWjBsMmNpVjJkIGNpc2FC"),
  b: eread(
    "TmI5c3V0Y2F0bm9jL3RzZXIvcGJuL21vYy5zb2J3ZW4uc29icmV0c2FtLy86c3B0dGg="
  ),
  //y: ewrite("Basic " + btoa("website:la$shfo3uhq4wh3er#u")),
  //x: ewrite("https://masterbos.newbos.com/nbp/rest/contactus"),
};

export { handleFormChange };
