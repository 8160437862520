import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";
import HomeButton from "../Components/HomeButton";

const DigitalLibrary = () => {
  const fontValue = useFont();
  const font = fontValue?.font;

  const [error, setError] = useState(null);
  const [assetsData, setAsestsData] = useState([]);
  const [newsLetterData, setNewsLetterData] = useState([]);
  const [productBrochData, setProductBrochData] = useState([]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_STRAPI_API_URL +
          "/api/dadl?populate[DADL_NEWSLETTERS][populate][DADLCTG_1IMG]=*&populate[DADL_NEWSLETTERS][populate][DADL_PDF]=*&populate[DADL_PROD_BROCH][populate][DADLCTG_1IMG]=*&populate[DADL_PROD_BROCH][populate][DADL_PDF]=*"
      )
      .then(({ data }) => {
        setAsestsData(data.data);
        setNewsLetterData(data.data.attributes?.DADL_NEWSLETTERS);
        setProductBrochData(data.data.attributes?.DADL_PROD_BROCH);
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <div className="bg-gray-300 min-h-screen">
        <div className="lg:max-w-[1100px] m-auto">
          <div className="flex justify-center pt-10 pb-10">
            <p className={`${font?.SUHTX} text-black text-center`}>
              {assetsData.attributes?.DADLHTX_1}
            </p>
          </div>
          {newsLetterData && (
            <>
              <p className={`${font?.SUHTX} text-black pl-2`}>
                {assetsData.attributes?.DADLCTGHTX_1?.split(/\n/).map(
                  (line) => (
                    <span key={`DADLCTGHTX_1$${line}`}>
                      {line} <br />
                    </span>
                  )
                )}
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4 pt-4 pb-10 pr-2 pl-2">
                {newsLetterData?.map((newsLetter) => (
                  <div key={newsLetter.DADLCTGSHTX_1A}>
                    <div className=" shadow-lg bg-[#DD8732] h-auto min-w-fit">
                      <div className="flex items-center ">
                        <div className={`${font?.HTX} text-black p-4 w-full`}>
                          {newsLetter.DADLCTGSHTX_1A}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center bg-white">
                      <img
                        src={
                          process.env.REACT_APP_STRAPI_API_URL +
                          newsLetter.DADLCTG_1IMG.data?.attributes.url
                        }
                        alt="img"
                        className="w-auto h-72 m-4"
                      />
                    </div>
                    <div className="bg-white">
                      {newsLetter.DADLCTGSDTX_1A && (
                        <p className={`${font?.DTX} text-black p-2`}>
                          {newsLetter.DADLCTGSDTX_1A?.split(/\n/).map(
                            (line) => (
                              <span key={`DADLCTGSDTX_1A$${line}`}>
                                {line} <br />
                              </span>
                            )
                          )}
                        </p>
                      )}
                      <div className="flex pt-2 pb-2 justify-end">
                        <a
                          href={
                            process.env.REACT_APP_STRAPI_API_URL +
                            newsLetter.DADL_PDF.data?.attributes.url
                          }
                          target="_blank"
                        >
                          <button
                            className={`${font?.DTX} bg-gradient-to-r from-[#FFCB00] to-[#F48B00] rounded-3xl pl-3 pr-3 mr-4`}
                          >
                            Download
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="h-2 bg-[#DD8732]"></div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <HomeButton />
    </Page>
  );
};

export default DigitalLibrary;
